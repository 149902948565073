import './bootstrap';
import '../css/app.scss';

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy';
import * as Sentry from "@sentry/vue";

// Vuetify
import 'vuetify/styles';
import '@mdi/font/css/materialdesignicons.css';
import {createVuetify} from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import {md1, md2, md3} from 'vuetify/blueprints';
import colors from 'vuetify/lib/util/colors';

let primary = colors.green.base;
switch (window.subdomain) {
    case 'gym':
        primary = colors.blue.base;
        break;
    case 'work':
        primary = colors.pink.base;
        break;
}

const vuetify = createVuetify({
    components,
    directives,
    blueprint: md3,
    theme: {
        defaultTheme: 'light',
        themes: {
            light: {
                colors: {
                    primary: primary,
                    secondary: colors.blueGrey.base,
                    dashboard: colors.green.base,
                    gym: colors.blue.base,
                    work: colors.pink.base,
                }
            }
        }
    }
});

const appName = 'Gym & Work';

createInertiaApp({
    title: (title) => title ? `${title} - ${appName}` : appName,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) });
        Sentry.init({
            app,
            dsn: import.meta.env.VITE_SENTRY_DSN,
            allowUrls: [/^https:\/\/[\w\-]+\.web-industry\.pro/],
            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for tracing.
            // We recommend adjusting this value in production
            tracesSampleRate: 1.0,
            // Capture Replay for 10% of all sessions,
            // plus for 100% of sessions with an error
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
        });
        app.use(plugin)
            .use(ZiggyVue)
            .use(vuetify)
            .mount(el);
        return app;
    },
    progress: {
        color: primary,
    },
});
